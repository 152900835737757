import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <div>
      <h1>Not Found Page</h1>

      <h3>You are in Not Found Page</h3>

      <Link to="/">
        {" "}
        <h5>Back to home</h5>
      </Link>
    </div>
  );
}

export default NotFoundPage;
