import { myConfig } from "../settings/config";
import { Constants } from "../settings/Constants";
import FileSaver from "file-saver";
export const Commons = {
  getApiUrlBase,
  callGetApi,
  callGetApiAsync,
  callPostApi,
  callApi,
  callApiAsync,
  callMultipartApi,
  callRawDataApi,
  callPutApi,
  getSessionStorageUserToken,
};

function getApiUrlBase() {
  return myConfig.isDev
    ? myConfig.contextRoot + "mockup/"
    : myConfig.apiUrlBase;
}

function callGetApi(service, requestData, callback) {
  callApi(service, requestData, "GET", callback);
}

async function callGetApiAsync(service, requestData) {
  try {
    return await callApiAsync(service, requestData, "GET");
  } catch (error) {
    return error;
  }
}

function callPostApi(service, requestData, callback) {
  callApi(service, requestData, "POST", callback);
}

function callPutApi(service, requestData, callback) {
  callApi(service, requestData, "PUT", callback);
}

function callApi(service, requestData, method, callback) {
  var headers = { "Content-Type": "application/json" };

  const userToken = getSessionStorageUserToken();

  if (userToken) {
    headers["Authorization"] = "Bearer " + userToken;
  }

  var options = {
    method: method,
    headers: headers,
  };

  if (method === "POST" || method === "PUT") {
    options["body"] = JSON.stringify(requestData.payload);
  }
  fetch(service, options)
    .then(function (resp) {
      var sessionUserData = localStorage.getItem(
        Constants.SESSION_KEY_USER_DATA
      );
      if (
        resp.status === 401 &&
        sessionUserData !== undefined &&
        sessionUserData !== null
      ) {
        localStorage.removeItem(Constants.SESSION_KEY_USER_DATA);
        window.location.reload();
      } else if (resp.status === 404) {
        callback({ ok: false, error: { message: "System Busy" } });
      } else {
        resp.json().then((data) => {
          if (data !== undefined && data !== null) {
            callback(data);
          } else {
            callback({ ok: false, error: { message: "System Busy" } });
          }
        });
      }
    })
    /*.then(function(retData){
		console.log(retData);
		callback(retData);
	})*/
    .catch(function (error) {
      callback({ ok: false, error: { message: "System Busy" } });
    });
}

function callApiAsync(service, requestData, method) {
  return new Promise((resolve, reject) => {
    var headers = { "Content-Type": "application/json" };
    if (requestData.userToken !== null) {
      headers["Authorization"] = "Bearer " + requestData.userToken;
    }

    var options = {
      method: method,
      headers: headers,
    };

    if (method === "POST" || method === "PUT") {
      options["body"] = JSON.stringify(requestData.payload);
    }
    fetch(service, options)
      .then((resp) => {
        var sessionUserData = localStorage.getItem(
          Constants.SESSION_KEY_USER_DATA
        );
        if (
          resp.status === 401 &&
          sessionUserData !== undefined &&
          sessionUserData !== null
        ) {
          localStorage.removeItem(Constants.SESSION_KEY_USER_DATA);
          window.location.reload();
        } else if (resp.status === 404) {
          reject({ ok: false, error: { message: "System Busy" } });
        } else {
          resp.json().then((data) => {
            if (data !== undefined && data !== null) {
              resolve(data);
            } else {
              reject({ ok: false, error: { message: "System Busy" } });
            }
          });
        }
      })
      .catch((error) => {
        reject({ ok: false, error: { message: "System Busy" } });
      });
  });
}

function callMultipartApi(service, requestData, formData, method, callback) {
  //var headers = {'Content-Type': 'multipart/form-data'};
  var headers = {};
  if (requestData.userToken !== null) {
    headers["Authorization"] = "Bearer " + requestData.userToken;
  }
  var options = {
    method: method,
    headers: headers,
  };
  if (method === "POST") {
    options["body"] = formData;
  }

  fetch(service, options)
    .then(function (resp) {
      if (resp.status === 401) {
        localStorage.removeItem(Constants.SESSION_KEY_USER_DATA);
        window.location.reload();
      }
      resp.json().then((data) => {
        callback(data);
      });
    })
    .catch(function (error) {
      //console.log(error);
      callback({ ok: false, errMsg: error });
    });
}

function callRawDataApi(service, requestData, fileName, callback = null) {
  var headers = { "Content-Type": "application/json" };
  if (requestData.userToken !== null) {
    headers["Authorization"] = "Bearer " + requestData.userToken;
  }
  var options = {
    method: "GET",
    headers: headers,
  };
  fetch(service, options)
    .then(function (resp) {
      var sessionUserData = localStorage.getItem(
        Constants.SESSION_KEY_USER_DATA
      );
      if (
        resp.status === 401 &&
        sessionUserData !== undefined &&
        sessionUserData !== null
      ) {
        localStorage.removeItem(Constants.SESSION_KEY_USER_DATA);
        window.location.reload();
      } else if (resp.status === 404) {
        //	callback({ok:false, error:{message:"System Busy"}});
      } else {
        return resp.blob();
      }
    })
    .then(function (blob) {
      if (blob) {
        FileSaver.saveAs(blob, fileName);
        if (callback) {
          callback(blob);
        }
      } else {
        if (callback) {
          callback(null);
        }
      }
    });
}

function getSessionStorageUserToken() {
  const sessionUserData = sessionStorage.getItem("USER_DATA");
  let sessionUserObj;
  if (sessionUserData !== undefined && sessionUserData !== null) {
    sessionUserObj = JSON.parse(sessionUserData);
  }

  if (!sessionUserObj) {
    return;
  }

  if (sessionUserObj.token === undefined || sessionUserObj.token === null) {
    return;
  }

  return sessionUserObj.token;
}
