import { myConfig } from "../settings/config";
import { Commons } from "./Commons";

export const TagServices = {
  getTemplateGroup,
  getTemplateGroupDetail,
  getTemplatesByTemplateGroupId,
  getTemplateDetailByTemplateId,
  getMaterialsByMaterialGroupId,
};

function getTemplateGroup(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2TagService}/tags?type=template-group&page=${requestData.page}&size=${requestData.size}&ad_size=${requestData.ad_size}`;
  Commons.callGetApi(serviceUrl, requestData, callback);
}

function getTemplateGroupDetail(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2TagService}/tags?type=template-group&page=1&size=1&type_id=${requestData.templateGroupId}`;
  Commons.callGetApi(serviceUrl, requestData, callback);
}

function getTemplatesByTemplateGroupId(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2TagService}/tags?type=template&page=1&size=100&group_id=${requestData.templateGroupId}`;
  Commons.callGetApi(serviceUrl, requestData, callback);
}

function getTemplateDetailByTemplateId(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2TagService}/tags?type=template&page=1&size=100&type_id=${requestData.templateId}`;
  Commons.callGetApi(serviceUrl, requestData, callback);
}

function getMaterialsByMaterialGroupId(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2TagService}/tags?type=material&page=1&size=100&group_id=${requestData.group_id}`;
  Commons.callGetApi(serviceUrl, requestData, callback);
}
