let apiLubanV2UserService = "",
  apiLubanV2TagService = "",
  apiLubanV2CMSService = "",
  apiLubanV2UploadService = "",
  bucketUrl = "",
  authToken = "";

switch (process.env.REACT_APP_ABM_ENV) {
  case "uat":
    apiLubanV2UserService = "http://34.117.238.92/user-service";
    apiLubanV2TagService = "http://34.117.238.92/tag-service";
    apiLubanV2CMSService = "http://34.117.238.92/cms-service";
    apiLubanV2UserService = "http://34.117.238.92/user-service";
    apiLubanV2UploadService =
      "https://adsdesign-upload-service-uat-tztwwupw2a-df.a.run.app";
    // REMARK-N: TO BE DELETE, get it from api response / browser session
    authToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJJZCI6IjYxYzE1MTViNmRhZWRiOWJiZmY3MDE1ZSIsImVtYWlsIjoibXl0dnN1cGVyLmFkbWluQHR2Yi5jb20iLCJuYW1lIjoiTXl0dlN1cGVyIEFkbWluIn0sImlhdCI6MTY0MDE1NTU4OCwiZXhwIjoxNzM0ODI4Mzg4fQ.qxWsER58qN4ayAEnBDO8VsBvHNdssgdw6x3nXF5-zO4";
    bucketUrl = "https://storage.googleapis.com/ads-design-uat/";
    break;
  case "prd":
    apiLubanV2UserService =
      "https://prod-ads-design-api.mytvsuper.com/user-service";
    apiLubanV2TagService =
      "https://prod-ads-design-api.mytvsuper.com/tag-service";
    apiLubanV2CMSService =
      "https://prod-ads-design-api.mytvsuper.com/cms-service";
    apiLubanV2UserService =
      "https://prod-ads-design-api.mytvsuper.com/user-service";
    apiLubanV2UploadService =
      "https://adsdesign-upload-service-prod-l65wgf2hjq-df.a.run.app";
    bucketUrl = "https://storage.googleapis.com/ads-design-prd/";
    // REMARK-N: TO BE DELETE, get it from api response / browser session
    authToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJJZCI6IjYxZDU4Mjg1ZGMzZGRjNDUxOWE3MWZmMCIsImVtYWlsIjoibXl0dnN1cGVyLmFkbWluQHR2Yi5jb20iLCJuYW1lIjoiTXl0dlN1cGVyIn0sImlhdCI6MTY0MTM4MjU0MCwiZXhwIjoxNjcyOTQwMTQwfQ.KrCoAoWw5HjNEtaOIx1__kiZI-nwWFo6rW9GB4oeSWg";
    break;
  case "local":
    apiLubanV2UserService = "http://34.117.238.92/user-service";
    apiLubanV2TagService = "http://localhost:3011";
    apiLubanV2CMSService = "http://localhost:3012";
    // apiLubanV2UserService = "http://localhost:3013";
    apiLubanV2UploadService =
      "https://adsdesign-upload-service-y72ou35lda-df.a.run.app";
    bucketUrl = "https://storage.googleapis.com/ads-design-dev/";
    break;
  default:
    // DEV
    apiLubanV2UserService = "http://34.117.142.220/user-service";
    apiLubanV2TagService = "http://34.117.142.220/tag-service";
    apiLubanV2CMSService = "http://34.117.142.220/cms-service";
    apiLubanV2UserService = "http://34.117.142.220/user-service";
    apiLubanV2UploadService =
      "https://adsdesign-upload-service-y72ou35lda-df.a.run.app";
    // REMARK-N: TO BE DELETE, get it from api response / browser session
    authToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJJZCI6IjYxYzJjOGFiNGIxZjgyZWE4ODRmZjQzOCIsImVtYWlsIjoibXl0dnN1cGVyLmFkbWluQHR2Yi5jb20iLCJuYW1lIjoibXl0dlN1cGVyIn0sImlhdCI6MTY0MDE1NTMxMiwiZXhwIjoxNzM0ODI4MTEyfQ.FlVw8lqoMKu8nYIaDOu9DVIW1KXDxNkfsO153pjREQ4";
    bucketUrl = "https://storage.googleapis.com/ads-design-dev/";
}

export const myConfig = {
  isDev: false,
  contextRoot: "/",
  apiLubanV2UserService,
  apiLubanV2TagService,
  apiLubanV2CMSService,
  apiLubanV2UploadService,
  bucketUrl,
  authToken,
};
