import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PublicRoutes from "./publicRoutes";
import PrivateRoute from "./privateRoutes";

import AdsDetailPage from "../containers/AdsDetailPage";
import Home from "../containers/Home";
import MaterialsGroupPage from "../containers/MaterialsGroupPage";
import NewTemplatePage from "../containers/NewTemplatePage";
import NotFoundPage from "../containers/NotFoundPage";
import TemplateGroupDetailPage from "../containers/TemplateGroupDetailPage";
import UpdateTemplatePage from "../containers/UpdateTemplatePage";
import MaterialsGroupListPage from "../containers/MaterialGroupListPage";
import LoginPage from "../containers/LoginPage";

const Router = ({ childProps }) => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path="/dashboard"
        component={Home}
        props={childProps}
      />

      <PrivateRoute
        exact
        path="/ad/:adsType"
        component={AdsDetailPage}
        props={childProps}
      />

      <PrivateRoute
        exact
        path="/ad/:adsType/:templateGroupId/new-template"
        component={NewTemplatePage}
        props={childProps}
      />

      <PrivateRoute
        exact
        path="/ad/:adsType/:templateGroupId"
        component={TemplateGroupDetailPage}
        props={childProps}
      />

      <PrivateRoute
        exact
        path="/ad/:adsType/:templateGroupId/:templateId/update"
        component={UpdateTemplatePage}
        props={childProps}
      />

      <PrivateRoute
        exact
        path="/materials/group"
        component={MaterialsGroupListPage}
        props={childProps}
      />

      <PrivateRoute
        exact
        path="/materials/group/:materialsGroupId"
        component={MaterialsGroupPage}
        props={childProps}
      />

      <Route path="/404" component={NotFoundPage} />
      <Route path="/login" component={LoginPage} />
      <Redirect from="/" to="/dashboard" />
      <Redirect from="*" to="/404" />
    </Switch>
  );
};

export default Router;
