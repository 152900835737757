import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Divider, Grid, Typography } from "@material-ui/core";

import DashboardCard from "../../components/DashboardCard";

import Header from "../../components/Header";

function HomePage() {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    body: {
      backgroundColor: "#f4f6f8",
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        style={{ background: "#f4f6f8" }}
      >
        <Typography
          color="textPrimary"
          variant="h4"
          style={{ marginLeft: "24px" }}
        >
          Template
        </Typography>
        <Divider style={{ margin: "10px 24px 24px" }} />
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <DashboardCard title="LREC" subtitle="300 X 250" href="/ad/lrec" />
            <DashboardCard
              title="Double Banner"
              subtitle="640 X 200"
              href="/ad/double-banner"
            />
            <DashboardCard
              title="Triple Banner"
              subtitle="640 X 300"
              href="/ad/triple-banner"
            />
            <DashboardCard
              title="Pop Up"
              subtitle="640 X 960"
              href="/ad/pop-up"
            />
            <DashboardCard
              title="Inverted L-Shape"
              subtitle="1920 X 1080"
              href="/ad/inverted-l-shape"
            />
          </Grid>
        </Container>
        <Typography
          color="textPrimary"
          variant="h4"
          style={{ marginLeft: "24px", marginTop: "50px" }}
        >
          Materials
        </Typography>
        <Divider style={{ margin: "10px 24px 24px" }} />
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <DashboardCard title="Material Groups" href="/materials/group" />
            {/* <DashboardCard title="Materials" href="/materials" /> */}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default HomePage;
