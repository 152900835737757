import queryString from "query-string";

import { myConfig } from "../settings/config";
import { Commons } from "./Commons";

export const MaterialGroupServices = {
  getMaterialGroupList,
  createMaterialGroup,
  updateMaterialGroupDetail,
  getMaterialGroupDetail,
  updateMaterialDetail,
};

function getMaterialGroupList(requestData, callback) {
  const queryStringify = queryString.stringify(requestData.queryParams);
  const serviceUrl = `${myConfig.apiLubanV2CMSService}/materials/groups?${queryStringify}`;
  Commons.callGetApi(serviceUrl, requestData, callback);
}

function createMaterialGroup(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2CMSService}/materials/groups`;
  Commons.callPostApi(serviceUrl, requestData, callback);
}

function updateMaterialGroupDetail(requestData, callback) {
  const serviceUrl = `${myConfig.apiLubanV2CMSService}/materials/groups/${requestData.materialGroupId}`;
  Commons.callPutApi(serviceUrl, requestData, callback);
}

function getMaterialGroupDetail(requestData, callback) {
  const serviceUrl = `${myConfig.apiLubanV2CMSService}/materials/groups/${requestData.materialGroupId}`;
  Commons.callGetApi(serviceUrl, requestData, callback);
}

function updateMaterialDetail(requestData, callback) {
  const serviceUrl = `${myConfig.apiLubanV2CMSService}/materials/${requestData.materialId}`;
  Commons.callPutApi(serviceUrl, requestData, callback);
}
