import { myConfig } from "../settings/config";
import { Commons } from "./Commons";

export const TemplateServices = {
  // getTemplateGroup,
  createTemplateGroup,
  postTemplate,
  putTemplate,
  postMaterials,
};

// function getTemplateGroup(requestData, callback) {
//   // var serviceUrl = `${myConfig.apiLubanV2CMSService}/templates/groups?page=${requestData.page}&size=${requestData.size}&ad_size=${requestData.ad_size}`;
//   var serviceUrl = `${myConfig.apiLubanV2CMSService}/templates/groups?page=${requestData.page}&size=${requestData.size}&ad_size=${requestData.ad_size}`;
//   Commons.callGetApi(serviceUrl, requestData, callback);
// }

function createTemplateGroup(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2CMSService}/templates/groups`;
  Commons.callPostApi(serviceUrl, requestData, callback);
}

function postTemplate(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2CMSService}/templates`;
  Commons.callPostApi(serviceUrl, requestData, callback);
}

function putTemplate(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2CMSService}/templates/${requestData.templateId}`;
  Commons.callPutApi(serviceUrl, requestData, callback);
}

function postMaterials(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2CMSService}/materials/`;
  Commons.callPostApi(serviceUrl, requestData, callback);
}
