import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import PerfectScrollbar from "react-perfect-scrollbar";
import { myConfig } from "../../settings/config";
import moment from "moment";

import { TagServices } from "../../services/TagServices";
import { TemplateServices } from "../../services/TemplateServices";
import { updateTemplateGroupDetail } from "../../services/TemplateGroupServices";

import Header from "../../components/Header";
import _ from "lodash";

function TemplateGroupDetailPage(props) {
  const history = useHistory();
  const templateGroupId = props.match.params.templateGroupId;
  const adsType = props.match.params.adsType;

  const [templateGroupDetail, setTemplateGroupDetail] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [templateGroupNameEN, setTemplateGroupNameEN] = useState("");
  const [templateGroupNameZH, setTemplateGroupNameZH] = useState("");
  const [templateGroupNameSC, setTemplateGroupNameSC] = useState("");
  const [tags, setTags] = useState([]);
  const [isLive, setIsLive] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [selectedTemplate, setselectedTemplate] = useState([]);
  const [selectedAllTemplate, setSelectedAllTemplate] = useState(false);

  useEffect(() => {
    TagServices.getTemplateGroupDetail(
      {
        templateGroupId,
      },
      loadTemplateGroupDetail
    );
  }, []);

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = () => {
    TagServices.getTemplatesByTemplateGroupId(
      {
        templateGroupId,
      },
      loadTemplates
    );
  };

  useEffect(() => {
    if (
      selectedTemplate.length === templates.length &&
      templates.length !== 0
    ) {
      setSelectedAllTemplate(true);
    } else {
      setSelectedAllTemplate(false);
    }
  }, [selectedTemplate.length, templates.length]);

  const loadTemplateGroupDetail = (respData) => {
    const data = respData.data.result[0];
    setTemplateGroupNameEN(data.language_eng);
    setTemplateGroupNameZH(data.language_cht);
    setTemplateGroupNameSC(data.language_chs);
    setTags(data.tags);
    setIsLive(data.is_live);
    setIsActive(data.is_active);
    setTemplateGroupDetail(data);
  };

  const loadTemplates = (respData) => {
    setTemplates(respData.data.result);
  };

  const handleInputChange = (e, lang) => {
    if (lang === "EN") {
      setTemplateGroupNameEN(e.target.value);
    } else if (lang === "ZH") {
      setTemplateGroupNameZH(e.target.value);
    } else if (lang === "SC") {
      setTemplateGroupNameSC(e.target.value);
    } else {
      setTags(e.target.value.split(","));
    }
  };

  const formatDate = (date) => {
    return moment(date).local().format("YYYY-MM-DD HH:mm:ss") || "";
  };

  const updateTemplateGroupDetailHandler = () => {
    const apiData = {
      templateGroupId: templateGroupDetail._id,
      payload: {
        language_eng: templateGroupNameEN,
        language_cht: templateGroupNameZH,
        language_chs: templateGroupNameSC,
        tags: tags,
        is_live: isLive,
        is_active: isActive,
      },
    };
    updateTemplateGroupDetail(apiData, (apiRes) => {
      if (!apiRes.ok) {
        alert(apiRes?.error?.message || "unknown error");
      }

      alert("Update success");
    });
  };
  if (!templateGroupDetail) {
    return null;
  }
  const toggleSelectAllTemplate = () => {
    if (selectedTemplate.length === templates.length) {
      setSelectedAllTemplate(false);
      setselectedTemplate([]);
    } else {
      setSelectedAllTemplate(true);
      setselectedTemplate(templates);
    }
  };

  const handleTemplateCheckboxChange = (t) => {
    setselectedTemplate(_.xorBy(selectedTemplate, [t], "_id"));
  };

  const handleChangeSelectedLiveToUser = (newBool) => {
    // Promise.all(
    selectedTemplate.map(async (t) => {
      await updateTemplate(t, newBool);
    });
    // )
  };

  const updateTemplate = async (t, newBool) => {
    const newTemplate = t;
    newTemplate.is_live = newBool;
    newTemplate.file_path = newTemplate.bucket_path;
    const requestData = {
      templateId: newTemplate._id,
      payload: newTemplate,
    };
    await TemplateServices.putTemplate(requestData, getTemplates);
  };
  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        style={{ background: "#f4f6f8", minHeight: "100vh" }}
      >
        <Container maxWidth="md">
          {/* <Breadcrumbs
            aria-label="breadcrumb"
            style={{ margin: "12px 0 24px" }}
          >
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Link color="inherit" href="/">
              {props.match.params.adsType}
            </Link>
            <Typography color="textPrimary">
              {props.match.params.templateId}
            </Typography>
          </Breadcrumbs> */}
          <Card>
            <CardHeader title="Template Group Info" />
            <CardContent>
              <form noValidate autoComplete="off">
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <TextField
                      label="English:"
                      style={{ margin: 5 }}
                      placeholder=""
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={templateGroupNameEN}
                      onChange={(e) => handleInputChange(e, "EN")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="繁體中文:"
                      style={{ margin: 5 }}
                      placeholder=""
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={templateGroupNameZH}
                      onChange={(e) => handleInputChange(e, "ZH")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="简体中文:"
                      style={{ margin: 5 }}
                      placeholder=""
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={templateGroupNameSC}
                      onChange={(e) => handleInputChange(e, "SC")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      Tags: {tags ? tags.join(",") : ""}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      is Live: {isLive ? "true" : "false"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      is Actibe: {isActive ? "true" : "false"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography color="textPrimary"></Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color="textPrimary"></Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // xs={12}
                  >
                    <div style={{ display: "flex", marginTop: "30px" }}>
                      <div style={{ margin: "auto" }}>
                        <Button
                          color="primary"
                          startIcon={<ArrowLeftIcon />}
                          size="medium"
                          variant="outlined"
                          style={{ margin: "20px" }}
                          onClick={() => props.history.goBack()}
                        >
                          Back
                        </Button>
                        <Button
                          style={{ margin: "5px" }}
                          variant="contained"
                          color="primary"
                          onClick={updateTemplateGroupDetailHandler}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
          <Divider style={{ margin: "30px 0" }}></Divider>
          <Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "15px 0" }}
                onClick={() => {
                  handleChangeSelectedLiveToUser(true);
                }}
              >
                Open selected to User
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "15px" }}
                onClick={() => {
                  handleChangeSelectedLiveToUser(false);
                }}
              >
                Close selected to User
              </Button>
            </Grid>
          </Grid>
          <Card>
            <CardHeader title="Templates" />
            <Divider />
            <PerfectScrollbar style={{ overflow: "auto" }}>
              <Box sx={{ minWidth: 400 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Template Name</TableCell> */}
                      <TableCell>
                        <Checkbox
                          checked={selectedAllTemplate}
                          onChange={toggleSelectAllTemplate}
                        />
                      </TableCell>
                      <TableCell>Thumbnail</TableCell>
                      <TableCell>Tag(s)</TableCell>
                      <TableCell>Created at</TableCell>
                      <TableCell>Updated at</TableCell>
                      <TableCell>Open to user</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {templates
                      ? templates.map((template) => (
                          <TableRow hover key={template._id}>
                            <TableCell>
                              <Checkbox
                                checked={
                                  !_.isEmpty(
                                    selectedTemplate.filter(
                                      (t) => t._id === template._id
                                    )
                                  )
                                }
                                onChange={() => {
                                  handleTemplateCheckboxChange(template);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <img
                                src={myConfig.bucketUrl + template.bucket_path}
                                style={{
                                  maxWidth: "200px",
                                  maxHeight: "100px",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              {template.tags ? template.tags.join(",") : ""}
                            </TableCell>
                            <TableCell>
                              {formatDate(template.created_at)}
                            </TableCell>
                            <TableCell>
                              {formatDate(template.updated_at)}
                            </TableCell>
                            <TableCell>
                              {template.is_live ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                endIcon={<ArrowRightIcon />}
                                size="medium"
                                variant="contained"
                                onClick={() =>
                                  history.push(
                                    `/ad/${adsType}/${templateGroupId}/${template._id}/update`
                                  )
                                }
                              >
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
          <div style={{ display: "flex" }}>
            <Button
              color="primary"
              startIcon={<ArrowLeftIcon />}
              size="medium"
              variant="outlined"
              style={{ margin: "20px auto 0" }}
              onClick={() => props.history.goBack()}
            >
              Back
            </Button>
          </div>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default TemplateGroupDetailPage;
