import { myConfig } from "../settings/config";
import { Commons } from "./Commons";

export const AuthServices = {
  login,
  // logout
};

function login(requestData, callback) {
  var serviceUrl = `${myConfig.apiLubanV2UserService}/sessions`;
  Commons.callPostApi(serviceUrl, requestData, callback);
}
