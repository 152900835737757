import React from "react";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";

const Header = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Button
            href="/dashboard"
            component={Link}
            style={{
              color: "#FFFFFF",
              textTransform: "none",
              fontSize: "1.25rem",
              textDecoration: "none",
            }}
          >
            Ads Graphic Design Tool Admin Panel
          </Button>
        </Typography>
        <Button
          color="inherit"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            sessionStorage.clear();
            window.location.reload();
            return;
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
