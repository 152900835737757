import axios from "axios";

import { myConfig } from "../settings/config";
import { Commons } from "./Commons";

export const UploadServices = {
  uploadFile,
  uploadFiles,
};

async function uploadFile(requestData, uploadFile, callback) {
  try {
    var serviceUrl = `${myConfig.apiLubanV2UploadService}/upload`;
    let bodyFormData = new FormData();
    bodyFormData.append("files", uploadFile);

    const apiObj = {
      method: "post",
      url: serviceUrl,
      data: bodyFormData,
    };

    const userToken = Commons.getSessionStorageUserToken();

    if (userToken) {
      apiObj.headers = {
        Authorization: `Bearer ${userToken}`,
      };
    }

    let apiResult = await axios();
    callback(apiResult.data);
  } catch (e) {
    callback(e);
  }
}

async function uploadFiles(requestData, uploadFile, callback) {
  try {
    let serviceUrl = `${myConfig.apiLubanV2UploadService}/upload`;
    let bodyFormData = new FormData();
    Array.from(uploadFile).forEach((file) => {
      bodyFormData.append("files", file);
    });

    const apiObj = {
      method: "post",
      url: serviceUrl,
      data: bodyFormData,
    };

    const userToken = Commons.getSessionStorageUserToken();

    if (userToken) {
      apiObj.headers = {
        Authorization: `Bearer ${userToken}`,
      };
    }

    let apiResult = await axios(apiObj);
    callback(apiResult.data);
  } catch (e) {
    callback(e);
  }
}
