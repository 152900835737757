import * as React from "react";
import { useHistory, Redirect } from "react-router-dom";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { AuthServices } from "../../services/AuthServices";

function LoginPage() {
  const history = useHistory();

  React.useEffect(() => {
    let sessionUserData = sessionStorage.getItem("USER_DATA");
    let userIsLoggin = false;
    if (sessionUserData !== undefined && sessionUserData !== null) {
      sessionUserData = JSON.parse(sessionUserData);
      if (
        sessionUserData.token !== undefined &&
        sessionUserData.token !== null
      ) {
        userIsLoggin = true;
        history.push("/dashboard");
      } else {
        this.resetLoginState();
      }
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const requestData = {
      payload: {
        email: data.get("email"),
        pwd: data.get("password"),
      },
    };
    AuthServices.login(requestData, postLoginCallback);
  };

  const postLoginCallback = (respData) => {
    if (respData.ok) {
      sessionStorage.setItem("USER_DATA", JSON.stringify(respData.data));
      window.location.reload();
    } else {
      alert(respData.error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Account"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
          <Button
            color="primary"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ marginTop: "30px" }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
