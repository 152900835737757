import React, { useState, useEffect } from "react";
import _ from "lodash";
import { fabric } from "fabric";

import {
  Button,
  ButtonGroup,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Chip,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { Card, IconButton, styled } from "@material-ui/core";
import { TwitterPicker } from "react-color";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { TagServices } from "../../services/TagServices";
import { UploadServices } from "../../services/UploadServices";
import { TemplateServices } from "../../services/TemplateServices";
import { MaterialGroupServices } from "../../services/MaterialGroupServices";
import { myConfig } from "../../settings/config";
import "./NewTemplatePage.css";
import transparent_background from "../../img/transparent_background.png";

import Header from "../../components/Header";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const NewTemplatePage = (props) => {
  const templateGroupId = props.match.params.templateGroupId;
  const adsType = props.match.params.adsType;

  const [canvas, setCanvas] = useState("");
  const [iTextIDcounter, setITextIDcounter] = useState(0);
  const [iTextArray, setITextArray] = useState([]);
  const [imageIDcounter, setImageIDcounter] = useState(0);
  const [imageArray, setImageArray] = useState([]);
  const [productImage, setProductImage] = useState();
  const [iconImage, setIconImage] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const [uploadImageErrorMessage, setUploadImageErrorMessage] = useState();
  const [canvasData, setCanvasData] = useState();
  const [zIndexCounter, setZIndexCounter] = useState(1);

  const [backgroundColor, setBackgroundColor] = useState("#dddddd");
  const fonts = ["NotoSansCJKtc", "MYeun", "黑繁", "Arial", "Helvetica"];
  const [bucketUrl, setBucketUrl] = useState("");
  const [materialGroupList, setMaterialGroupList] = useState(null);
  const [selectedMaterialGroupList, setSelectedMaterialGroupList] = useState(
    []
  );
  const [selectedMaterialGroup, setSelectedMaterialGroup] = useState("");
  const [materialList, setMaterialList] = useState([]);

  let canvasWidth = 0;
  let canvasHeight = 0;
  let adWidth = 0;
  let adHeight = 0;
  const adType = props.match.params.adsType;

  switch (adType) {
    case "lrec":
      canvasWidth = 300;
      canvasHeight = 250;
      adWidth = 300;
      adHeight = 250;
      break;
    case "double-banner":
      canvasWidth = 640;
      canvasHeight = 200;
      adWidth = 640;
      adHeight = 200;
      break;
    case "triple-banner":
      canvasWidth = 640;
      canvasHeight = 300;
      adWidth = 640;
      adHeight = 300;
      break;
    case "pop-up":
      // canvasWidth = 640;
      // canvasHeight = 960;
      canvasWidth = 320; // for 2x scale
      canvasHeight = 480;
      adWidth = 640;
      adHeight = 960;
      break;
    case "inverted-l-shape":
      // canvasWidth = 1920;
      // canvasHeight = 1080;
      canvasWidth = 960; // for 2x scale
      canvasHeight = 540;
      adWidth = 1920;
      adHeight = 1080;
      break;
    default:
      canvasWidth = 300;
      canvasHeight = 250;
      adWidth = 300;
      adHeight = 250;
  }

  // const canvasImageMaxWidth = canvasWidth * 0.6;
  // const canvasImageMaxHeight = canvasHeight * 0.6;

  const canvasImageMaxWidth = canvasWidth;
  const canvasImageMaxHeight = canvasHeight;

  const imageSizeLimit = 1000 * 1000; // 100kb
  const productImageSizeLimit = 1000 * 1000; // 100kb
  const iconImageSizeLimit = 1000 * 1000; // 100kb
  const ImagbackgroundeSizeLimit = 1000 * 1000; // 100kb

  useEffect(() => {
    const _canvas = new fabric.Canvas("canvas", {
      width: canvasWidth,
      height: canvasHeight,
      containerClass: "canvas-container",
      backgroundColor: backgroundColor,
      stopContextMenu: false, // production should true
    });

    setCanvas(_canvas);

    if (adType === "inverted-l-shape") {
      // For inverted-L
      const clipPath = new fabric.Group([
        new fabric.Rect({
          // width: 1537,
          // height: 216,
          // top: 864
          width: 770, // for 2x scale
          height: 109,
          top: 432,
          left: -1,
        }),
        new fabric.Rect({
          // width: 384,
          // height: 1080,
          // left: 1536
          width: 193, // for 2x scale
          height: 541,
          left: 768,
          top: -1,
        }),
      ]);
      _canvas.clipPath = clipPath;
    }

    _canvas.renderAll();
  }, []);

  useEffect(() => {
    if (canvas) {
      let scale = 1;
      if (adType === "invertedLShape" || adType === "popUpBanner") {
        scale = 2;
      }
      setDataUrl(
        canvas.toDataURL({
          format: "jpeg",
          quality: 1,
          multiplier: scale, // For Sunny's double 1080 scale idea
        })
      );
      setFileSize(
        Math.round(((dataUrl.length - "data:image/png;base64,".length) * 3) / 4)
      );
    }
  });

  useEffect(() => {
    MaterialGroupServices.getMaterialGroupList(
      {
        queryParams: {
          page: 1,
          size: 100,
        },
      },
      // initState
      loadMaterialGroup
    );
  }, []);

  const loadMaterialGroup = (respData) => {
    if (!respData.data) {
      return;
    }
    setMaterialGroupList(respData.data);
  };

  const handleTextArrayChange = (id, item, value) => {
    const objIndex = iTextArray.findIndex((obj) => obj.id === id);
    let newArray = [...iTextArray];
    newArray[objIndex].set(item, value);
    setITextArray(newArray);
    canvas.renderAll();
  };

  const handleAddText = () => {
    const newItext = new fabric.IText(`範例文字`, {
      left: (50 * (iTextIDcounter + 1)) % (canvasWidth - 75),
      // top: (50 * (iTextIDcounter + 1)) % (canvasHeight - 75),
      top:
        adType === "inverted-l-shape"
          ? 457
          : (50 * (iTextIDcounter + 1)) % (canvasHeight - 75),
      id: `itext-${iTextIDcounter}`,
      editable: false,
      _controlsVisibility: {
        tl: true,
        tr: true,
        br: true,
        bl: true,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: true,
      },
      expanded: false,
    });

    canvas.add(newItext);
    canvas.renderAll();
    setITextArray((oldArray) => [...oldArray, newItext]);
    setITextIDcounter(iTextIDcounter + 1);
  };

  const handleRemoveInput = (id) => {
    const objIndex = iTextArray.findIndex((obj) => obj.id === id);
    const list = [...iTextArray];
    list.splice(objIndex, 1);
    setITextArray(list);
    canvas.remove(iTextArray[objIndex]);
    canvas.renderAll();
  };

  const addImageByBase64 = (e, id, maxWidth, maxHeight, callback) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const imgObj = new Image();
      imgObj.src = event.target.result;
      imgObj.onload = function () {
        // base 64 version
        let image = new fabric.Image(imgObj);
        image.set({
          id: id,
          zIndex: zIndexCounter,
          _controlsVisibility: {
            tl: true,
            tr: true,
            br: true,
            bl: true,
            ml: false,
            mt: false,
            mr: false,
            mb: false,
            mtr: true,
          },
          left: adType === "invertedLShape" ? 50 : null,
          top: adType === "invertedLShape" ? 482 : null,
        });

        if (image.width > maxWidth || image.height > maxHeight) {
          if (image.width / maxWidth > image.height / maxHeight) {
            image.scaleToWidth(maxWidth);
          } else {
            image.scaleToHeight(maxHeight);
          }
        }
        setZIndexCounter(zIndexCounter + 1);
        if (adType !== "invertedLShape") {
          canvas.centerObject(image);
        }
        canvas.add(image);
        canvas.renderAll();
        callback(image);
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleAddImage = (event) => {
    if (event.target.files[0].size >= imageSizeLimit) {
      setUploadImageErrorMessage(
        `File size reach ${imageSizeLimit / 1000} KB limitation`
      );
    } else {
      addImageByBase64(
        event,
        `image-${imageIDcounter}`,
        canvasImageMaxWidth,
        canvasImageMaxHeight,
        (image) => {
          imageArray.push(image);
          setImageIDcounter(imageIDcounter + 1);
          setUploadImageErrorMessage();
        }
      );
    }
  };

  const handleAddProductImage = (event) => {
    if (event.target.files[0].size >= productImageSizeLimit) {
      setUploadImageErrorMessage(
        `File size reach ${productImageSizeLimit / 1000} KB limitation`
      );
    } else {
      handleRemoveProductImage();
      addImageByBase64(
        event,
        "product-image",
        canvasImageMaxWidth,
        canvasImageMaxHeight,
        (image) => {
          setProductImage(image);
          setUploadImageErrorMessage();
        }
      );
    }
  };

  const handleAddIconImage = (event) => {
    if (event.target.files[0].size >= iconImageSizeLimit) {
      setUploadImageErrorMessage(
        `File size reach ${iconImageSizeLimit / 1000} KB limitation`
      );
    } else {
      handleRemoveIconImage();
      addImageByBase64(
        event,
        "icon-image",
        canvasImageMaxWidth,
        canvasImageMaxHeight,
        (image) => {
          setIconImage(image);
          setUploadImageErrorMessage();
        }
      );
    }
  };

  const addBackgroundImageByBase64 = (e, id, maxWidth, maxHeight, callback) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const imgObj = new Image();
      imgObj.src = event.target.result;
      imgObj.onload = function () {
        // base 64 version
        let image = new fabric.Image(imgObj);
        image.set({
          id: id,
        });
        if (image.width / maxWidth > image.height / maxHeight) {
          image.scaleToWidth(maxWidth);
        } else {
          image.scaleToHeight(maxHeight);
        }
        canvas.centerObject(image);
        canvas.setBackgroundImage(image);
        canvas.renderAll();
        callback(image);
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleAddBackgroundImage = (event) => {
    if (event.target.files[0].size >= ImagbackgroundeSizeLimit) {
      setUploadImageErrorMessage(
        `File size reach ${ImagbackgroundeSizeLimit / 1000} KB limitation`
      );
    } else {
      handleRemoveBackgroundImage();
      addBackgroundImageByBase64(
        event,
        "background-image",
        canvasWidth,
        canvasHeight,
        (image) => {
          setBackgroundImage(image);
          setUploadImageErrorMessage();
        }
      );
    }
  };

  const handleRemoveImage = (id) => {
    const objIndex = imageArray.findIndex((obj) => obj.id === id);
    const list = [...imageArray];
    list.splice(objIndex, 1);
    setImageArray(list);
    canvas.remove(imageArray[objIndex]);
    canvas.renderAll();
  };

  const handleRemoveProductImage = () => {
    setProductImage();
    canvas.remove(productImage);
    canvas.renderAll();
  };

  const handleRemoveIconImage = () => {
    setIconImage();
    canvas.remove(iconImage);
    canvas.renderAll();
  };

  const handleRemoveBackgroundImage = () => {
    canvas.setBackgroundImage(null);
    setBackgroundImage();
    // canvas.remove(backgroundImage);
    canvas.renderAll();
  };

  const setCanvasBackgroundColor = (color) => {
    if (!canvas) {
      console.log("no canvas");
      return;
    }
    setBackgroundColor(color);
    canvas.setBackgroundColor(color);
    canvas.renderAll();
  };

  const handleForward = () => {
    const activeObj = canvas.getActiveObject();
    if (!activeObj) {
      return;
    }
    let curObject = canvas.getActiveObject();
    const curZIndex = canvas.getActiveObject().zIndex;
    const textArr = iTextArray;
    const imageArr = imageArray;

    textArr.forEach((itext) => {
      if (itext.zIndex === curZIndex + 1) {
        itext.zIndex = curZIndex;
        setITextArray(textArr);
      }
    });
    imageArr.forEach((img) => {
      if (img.zIndex === curZIndex + 1) {
        img.zIndex = curZIndex;
        setImageArray(imageArr);
      }
    });
    if (productImage && productImage.zIndex === curZIndex + 1) {
      productImage.zIndex = curZIndex;
      setProductImage(productImage);
    }
    if (iconImage && iconImage.zIndex === curZIndex + 1) {
      iconImage.zIndex = curZIndex;
      setIconImage(iconImage);
    }
    curObject.zIndex = curZIndex + 1;
    canvas.setActiveObject(curObject);
    canvas.bringForward(canvas.getActiveObject());
  };

  const handleBackward = () => {
    const activeObj = canvas.getActiveObject();
    if (!activeObj) {
      return;
    }

    let curObject = canvas.getActiveObject();
    const curZIndex = canvas.getActiveObject().zIndex;
    const textArr = iTextArray;
    const imageArr = imageArray;

    textArr.forEach((itext) => {
      if (itext.zIndex === curZIndex - 1) {
        itext.zIndex = curZIndex;
        setITextArray(textArr);
      }
    });
    imageArr.forEach((img) => {
      if (img.zIndex === curZIndex - 1) {
        img.zIndex = curZIndex;
        setImageArray(imageArr);
      }
    });
    if (productImage && productImage.zIndex === curZIndex - 1) {
      productImage.zIndex = curZIndex;
      setProductImage(productImage);
    }
    if (iconImage && iconImage.zIndex === curZIndex - 1) {
      iconImage.zIndex = curZIndex;
      setIconImage(iconImage);
    }
    curObject.zIndex = curZIndex - 1;
    canvas.setActiveObject(curObject);

    canvas.sendBackwards(canvas.getActiveObject());
  };

  const handleRemoveObject = () => {
    const activeObj = canvas.getActiveObject();
    if (!activeObj) {
      return;
    }
    canvas.remove(canvas.getActiveObject());
  };

  const handleCanvasToJSON = () => {
    setCanvasData(canvas.toJSON("id"));
    return canvas.toJSON("id");
  };

  const [dataUrl, setDataUrl] = useState("");
  const [fileSize, setFileSize] = useState();

  const handleSave = () => {
    let file = null;
    let url = "";
    let scale = 1;
    if (adType === "invertedLShape" || adType === "popUpBanner") {
      scale = 2;
    }

    let canvasDataURL = canvas.toDataURL({
      format: "png",
      quality: 1,
      multiplier: scale, // For Sunny's double 1080 scale idea
    });

    fetch(canvasDataURL)
      .then((res) => res.blob())
      .then(async (b) => {
        file = new File([b], `${adType}.png`, { type: "image/png" });
        let requestData = {
          // userToken: props.userData.token
        };
        // console.log("file", file);
        url = await UploadServices.uploadFile(requestData, file, loadBucketUrl);
      });
  };

  const loadBucketUrl = (respData) => {
    setBucketUrl(respData.data.uploaded);
  };

  useEffect(async () => {
    if (canvas) {
      const canvasJSON = handleCanvasToJSON();
      let tags = [`templategroups-${templateGroupId}`];
      if (selectedMaterialGroupList.length) {
        selectedMaterialGroupList.forEach((materialGroup) => {
          tags.push(`materialgroups-${materialGroup._id}`);
        });
      }
      let requestData = {
        payload: {
          file_path: bucketUrl[0],
          canvas_data: JSON.stringify(canvasJSON),
          // canvas_data: JSON.stringify(canvas),
          is_live: true,
          tags: tags,
        },
      };
      await TemplateServices.postTemplate(requestData, loadPostResult);
    }
  }, [bucketUrl]);

  const loadPostResult = (respData) => {
    props.history.push("/ad/" + adsType + "/" + templateGroupId);
  };

  const handleHistoryBack = () => {
    props.history.goBack();
  };

  const handleTagSelected = (event) => {
    let arr = selectedMaterialGroupList;
    arr.push(event.target.value);
    setSelectedMaterialGroupList(
      _.uniqBy(arr, (e) => {
        return e._id;
      })
    );
  };

  const handleDeleteSelectedTag = (tag) => {
    let arr = selectedMaterialGroupList.filter((m) => m._id !== tag._id);
    setSelectedMaterialGroupList(arr);
  };

  const onChangeMaterialGroupHandler = (e) => {
    setSelectedMaterialGroup(e.target.value);
  };

  const getMaterials = async () => {
    await TagServices.getMaterialsByMaterialGroupId(
      {
        group_id: selectedMaterialGroup,
      },
      loadMaterials
    );
  };

  const loadMaterials = (respData) => {
    setMaterialList(respData.data.result);
  };

  useEffect(() => {
    getMaterials();
  }, [selectedMaterialGroup]);

  const addImageByURL = async (url, maxWidth, maxHeight, callback) => {
    const reader = new FileReader();
    let file = null;
    reader.onload = function (event) {
      const imgObj = new Image();
      imgObj.src = event.target.result;
      imgObj.onload = function () {
        let image = new fabric.Image(imgObj);
        image.set({
          // id: id,
          id: `material-${iTextIDcounter}`,
          zIndex: zIndexCounter,
          _controlsVisibility: {
            tl: true,
            tr: true,
            br: true,
            bl: true,
            ml: false,
            mt: false,
            mr: false,
            mb: false,
            mtr: true,
          },
        });

        if (image.width > maxWidth || image.height > maxHeight) {
          if (image.width / maxWidth > image.height / maxHeight) {
            image.scaleToWidth(maxWidth);
          } else {
            image.scaleToHeight(maxHeight);
          }
        }
        if (adType === "inverted-l-shape") {
          image.top = 432;
          image.left = 0;
        } else {
          canvas.centerObject(image);
        }
        canvas.add(image);
        canvas.renderAll();
        callback(image);
      };
    };

    fetch(url)
      .then((res) => {
        console.log("res", res);
        return res.blob();
      })
      .then((blob) => {
        file = new File([blob], "dot.png", { type: "image/png" });
        console.log(file);
        reader.readAsDataURL(file);
      });
  };

  return (
    <React.Fragment>
      <Header />
      <CssBaseline />
      <Container maxWidth="xl" id="new-template-page">
        <Grid container spacing={3} style={{ textAlign: "center" }}>
          <Grid item sm={12} md={12}>
            <h4>Preview</h4>
            <canvas id="canvas" />
            <div> Canvas Size = {fileSize / 1000 / 8} / 900,000 byte</div>
            <div className="new-template-item">
              <h4>Index</h4>
              <span>測試123abc</span>
              <div>
                <Button
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  color="primary"
                  onClick={handleForward}
                >
                  Upper
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBackward}
                >
                  Lower
                </Button>
              </div>
            </div>
            <div className="new-template-item">
              <h4>Remove</h4>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRemoveObject}
                >
                  Remove
                </Button>
              </div>
            </div>
          </Grid>

          <Grid item sm={12} md={12}>
            <div className="new-template-item">
              <h4>Text Input</h4>
              {iTextArray.map((iText) => {
                return (
                  <React.Fragment>
                    <Card style={{ maxWidth: 650, margin: "20px auto" }}>
                      {/* <CardActions disableSpacing style={{ padding: 20 }}> */}
                      <div style={{ padding: 20 }}>
                        <Grid container>
                          <Grid item xs={11}>
                            <TextField
                              value={iText.text}
                              className="new-template-input-field"
                              type="text"
                              onChange={(e) =>
                                handleTextArrayChange(
                                  iText.id,
                                  "text",
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              multiline
                              maxRows={4}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleRemoveInput(iText.id)}
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>

                        {/* <CardContent> */}
                        {/* <div className="new-template-item">
                            <TextField
                              value={iText.fontSize}
                              className="new-template-input-field"
                              type="text"
                              onChange={(e) =>
                                handleTextArrayChange(
                                  iText.id,
                                  "fontSize",
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              multiline
                              maxRows={4}
                              label="Font Size"
                            />
                          </div> */}
                        <Grid container>
                          <Grid item xs={6}>
                            {/* <div className="new-template-item"> */}
                            {/* <div style={{margin: "20px auto"}}> */}
                            <TwitterPicker
                              triangle="hide"
                              color={iText.fill}
                              onChange={(color) => {
                                handleTextArrayChange(
                                  iText.id,
                                  "fill",
                                  color.hex
                                );
                              }}
                            />
                            {/* </div> */}
                            {/* </div> */}
                          </Grid>
                          <Grid item xs={6}>
                            {/* <div className="new-template-item"> */}
                            <ButtonGroup
                              color="primary"
                              aria-label="text button group"
                              style={{ flexWrap: "wrap" }}
                            >
                              {fonts.map((font) => {
                                return (
                                  <Button
                                    key={font}
                                    style={{ marginTop: "20px" }}
                                    onClick={() =>
                                      handleTextArrayChange(
                                        iText.id,
                                        "fontFamily",
                                        font
                                      )
                                    }
                                  >
                                    {font}
                                  </Button>
                                );
                              })}
                            </ButtonGroup>
                            {/* </div> */}
                          </Grid>
                        </Grid>
                        {/* </CardContent> */}

                        {/* <ExpandMore
                          expand={iText.expanded}
                          onClick={() =>
                            handleTextArrayChange(
                              iText.id,
                              "expanded",
                              !iText.expanded
                            )
                          }
                          aria-expanded={iText.expanded}
                          aria-label="show more"
                        > }
                          <ExpandMoreIcon />
                        </ExpandMore> */}
                        {/* </CardActions> */}
                        {/* <Collapse
                        in={iText.expanded}
                        timeout="auto"
                        unmountOnExit
                      >
                        
                      </Collapse> */}
                      </div>
                    </Card>
                  </React.Fragment>
                );
              })}
              <div
                className="new-template-item"
                style={{ textAlign: "center" }}
              >
                <IconButton aria-label="delete" onClick={handleAddText}>
                  <AddCircleIcon fontSize="inherit" style={{ fontSize: 40 }} />
                </IconButton>
              </div>
            </div>
          </Grid>

          <Grid item sm={12} md={12}>
            <div className="new-template-item">
              <h4>Upload Section</h4>
              <div>
                <h5>Product Image</h5>
                <Button variant="contained" component="label" color="primary">
                  Upload
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAddProductImage}
                    hidden
                  />
                </Button>
                {productImage ? (
                  <div style={{ display: "flex" }}>
                    <img
                      src={productImage._originalElement.currentSrc}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "70px",
                        margin: "10px",
                      }}
                    />
                    <IconButton
                      aria-label="delete"
                      // size="large"
                      style={{ margin: "auto 0" }}
                      onClick={handleRemoveProductImage}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                ) : null}
              </div>
              <div>
                <h5>Icon Image</h5>
                <Button variant="contained" component="label" color="primary">
                  Upload
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAddIconImage}
                    hidden
                  />
                </Button>

                {iconImage ? (
                  <div style={{ display: "flex" }}>
                    <img
                      src={iconImage._originalElement.currentSrc}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "70px",
                        margin: "10px",
                      }}
                    />
                    <IconButton
                      aria-label="delete"
                      style={{ margin: "auto 0" }}
                      onClick={handleRemoveIconImage}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                ) : null}
              </div>
              {/* <div>
                <h5>Image</h5>
                <Button variant="contained" component="label" color="primary">
                  Upload
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAddImage}
                    hidden
                  />
                </Button>
                {imageArray.map((image) => {
                  return (
                    <React.Fragment>
                      <img
                        src={image._originalElement.currentSrc}
                        style={{
                          maxWidth: "100px",
                          maxHeight: "70px",
                          margin: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          // size="large"
                          style={{ margin: "auto 0" }}
                          onClick={() => handleRemoveImage(image.id)}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </img>
                    </React.Fragment>
                  );
                })}
              </div>
              {uploadImageErrorMessage ? (
                <Alert severity="error">{uploadImageErrorMessage}</Alert>
              ) : null} */}
            </div>
            <div className="new-template-item">
              <h4>Background Color</h4>
              <TwitterPicker
                color={backgroundColor}
                onChange={(color) => {
                  setCanvasBackgroundColor(color.hex);
                  // canvas.renderAll()
                }}
              />
            </div>
          </Grid>
          <Grid item sm={12} md={12}>
            <div>
              <h5>Background Image</h5>
              <Button variant="contained" component="label" color="primary">
                Upload
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAddBackgroundImage}
                  hidden
                />
              </Button>
              {backgroundImage ? (
                <div style={{ display: "flex" }}>
                  <img
                    src={backgroundImage._originalElement.currentSrc}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "70px",
                      margin: "10px",
                    }}
                  />
                  <IconButton
                    aria-label="delete"
                    // size="large"
                    style={{ margin: "auto 0" }}
                    onClick={handleRemoveBackgroundImage}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </div>
              ) : null}
            </div>
          </Grid>

          <Grid item sm={12} md={12}>
            <div>
              <h4>Tags</h4>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Age"
                onChange={handleTagSelected}
              >
                {materialGroupList
                  ? materialGroupList.map((material) => {
                      return (
                        <MenuItem value={material} key={material._id}>
                          {material.language_eng}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              {selectedMaterialGroupList
                ? selectedMaterialGroupList.map((m) => {
                    return (
                      <Chip
                        value={m}
                        label={m.language_eng}
                        variant="outlined"
                        // onClick={handleClick}
                        onDelete={() => handleDeleteSelectedTag(m)}
                      />
                    );
                  })
                : ""}
            </div>
          </Grid>

          <Grid item sm={12} md={12}>
            {/* Material */}
            {/* {JSON.stringify(materialGroupList)} */}
            {/* turn ( */}
            <FormControl component="fieldset">
              <FormLabel component="legend">Materials</FormLabel>
              <RadioGroup
                aria-label="gender"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <Grid container>
                  <Grid item xs={4}>
                    <FormControlLabel
                      // type="checkbox"
                      value={""}
                      control={<Radio />}
                      label={"All"}
                      // key={materialGroup}
                      checked={
                        selectedMaterialGroup == "" ||
                        selectedMaterialGroup == null
                      }
                      onChange={onChangeMaterialGroupHandler}
                    />
                  </Grid>
                  {materialGroupList
                    ? materialGroupList.map((materialGroup) => {
                        return (
                          <Grid item xs={4}>
                            <FormControlLabel
                              // type="checkbox"
                              value={materialGroup._id}
                              control={<Radio />}
                              label={materialGroup.language_eng}
                              // key={materialGroup}
                              checked={
                                selectedMaterialGroup === materialGroup._id
                              }
                              onChange={onChangeMaterialGroupHandler}
                            />
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
              </RadioGroup>
            </FormControl>
            <div
              style={{ display: "flex", margin: "20px auto", flexWrap: "wrap" }}
            >
              {materialList.map((material) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "100px",
                      height: "100px",
                      margin: "15px",
                      border: "1px black solid",
                      backgroundImage: `url(${transparent_background})`,
                    }}
                  >
                    <img
                      style={{
                        maxWidth: "98px",
                        maxHeight: "98px",
                        margin: "auto",
                      }}
                      // className={`luban-v2-template${selectedTemplate === template._id ? '-selected' : '' }`}
                      src={myConfig.bucketUrl + material.bucket_path}
                      onClick={() =>
                        addImageByURL(
                          myConfig.bucketUrl + material.bucket_path,
                          canvasImageMaxWidth,
                          canvasImageMaxHeight,
                          () => {
                            // console.log("click");
                          }
                        )
                      }
                      key={material._id}
                    />
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handleHistoryBack}
          >
            <ArrowBackIcon /> Back
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            color="primary"
            onClick={handleSave}
          >
            <SaveIcon /> Save
          </Button>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default NewTemplatePage;
