import "./App.css";

import Router from "./routes";

function App() {
  let sessionUserData = sessionStorage.getItem("USER_DATA");
  let userIsLoggin = false;
  if (sessionUserData !== undefined && sessionUserData !== null) {
    sessionUserData = JSON.parse(sessionUserData);
    if (sessionUserData.token !== undefined && sessionUserData.token !== null) {
      userIsLoggin = true;
    } else {
      this.resetLoginState();
    }
  }

  const childProps = {
    isLoggedIn: userIsLoggin,
  };

  return <Router childProps={childProps} />;
}

export default App;
