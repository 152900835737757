import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import PerfectScrollbar from "react-perfect-scrollbar";
import { MaterialGroupServices } from "../../services/MaterialGroupServices";

import Header from "../../components/Header";

const MaterialGroupListPage = (props) => {
  const history = useHistory();

  const [newMaterialGroupNameEN, setNewMaterialGroupNameEN] = useState("");
  const [newMaterialGroupNameZH, setNewMaterialGroupNameZH] = useState("");
  const [newMaterialGroupNameSC, setNewMaterialGroupNameSC] = useState("");
  const [tags, setTags] = useState([]);
  // const [isLive, setIsLive] = useState(null);

  const [materialList, setMaterialList] = useState(null);

  const getMaterialList = () => {
    MaterialGroupServices.getMaterialGroupList(
      {
        queryParams: {
          page: 1,
          size: 100,
        },
      },
      initState
    );
  };

  useEffect(() => {
    getMaterialList();
  }, []);

  function initState(apiRes) {
    if (!apiRes.data) {
      return;
    }

    setMaterialList(apiRes.data);
  }

  if (!materialList) {
    return null;
  }

  const handleAddTemplateGroup = async () => {
    const payload = {
      language_eng: newMaterialGroupNameEN,
      language_cht: newMaterialGroupNameZH,
      language_chs: newMaterialGroupNameSC,
      // tags,
      is_live: true,
    };
    await MaterialGroupServices.createMaterialGroup(
      {
        payload,
      },
      getMaterialList
    );
  };

  const handleInputChange = (e, lang) => {
    setTimeout(() => {
      if (lang === "EN") {
        setNewMaterialGroupNameEN(e.target.value);
      } else if (lang === "ZH") {
        setNewMaterialGroupNameZH(e.target.value);
      } else if (lang === "SC") {
        setNewMaterialGroupNameSC(e.target.value);
      } else {
        setTags(e.target.value.split(","));
      }
    }, 200);
  };

  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        style={{ background: "#f4f6f8", minHeight: "100vh" }}
      >
        <Container>
          {/* <Breadcrumbs
            aria-label="breadcrumb"
            style={{ margin: "12px 0 24px" }}
          >
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">
              {props.match.params.adsType}
            </Typography>
          </Breadcrumbs> */}

          <Card>
            <CardHeader title="New Material Group" />
            <CardContent>
              <form noValidate autoComplete="off">
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="name-in-english"
                      label="English"
                      onChange={(e) => handleInputChange(e, "EN")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="name-in-english"
                      label="繁體中文"
                      onChange={(e) => handleInputChange(e, "ZH")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="name-in-english"
                      label="简体中文"
                      onChange={(e) => handleInputChange(e, "SC")}
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="tags"
                      label="Tags"
                      defaultValue={tags}
                      onChange={(e) => handleInputChange(e, "tags")}
                    />
                  </Grid> */}
                </Grid>
              </form>

              <div style={{ display: "flex", marginTop: "30px" }}>
                <div style={{ margin: "auto" }}>
                  <Button
                    color="primary"
                    startIcon={<ArrowLeftIcon />}
                    size="medium"
                    variant="outlined"
                    style={{ margin: "20px" }}
                    onClick={() => props.history.goBack()}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none", margin: "20px" }}
                    onClick={handleAddTemplateGroup}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>

          <Divider style={{ margin: "30px 0" }}></Divider>
          <Card>
            <CardHeader title="Material Group" />
            <Divider />
            <PerfectScrollbar style={{ overflow: "auto" }}>
              <Box sx={{ minWidth: 800 }}></Box>
              {/* <TableContainer component={Paper}> */}
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Eng</TableCell>
                    <TableCell>Cht</TableCell>
                    <TableCell>Chs</TableCell>
                    {/* <TableCell>tags</TableCell>
                    <TableCell>Open to user</TableCell> */}
                    <TableCell></TableCell>
                    {/* <TableCell>Is Active</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materialList.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.language_eng}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.language_cht}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.language_chs}
                      </TableCell>
                      {/* <TableCell>
                        {row.tags ? row.tags.join(",") : ""}
                      </TableCell> */}
                      {/* <TableCell>{row.is_live ? "Y" : "N"}</TableCell> */}
                      <TableCell>
                        <Button
                          color="primary"
                          endIcon={<ArrowRightIcon />}
                          size="medium"
                          variant="contained"
                          onClick={() =>
                            history.push(`${props.match.url}/${row._id}`)
                          }
                        >
                          View all
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Card>
          <div style={{ display: "flex" }}>
            <Button
              color="primary"
              startIcon={<ArrowLeftIcon />}
              size="medium"
              variant="outlined"
              style={{ margin: "20px auto 0" }}
              onClick={() => props.history.goBack()}
            >
              Back
            </Button>
          </div>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default MaterialGroupListPage;
