// import useSWR from 'swr';
// import { Link, useParams } from 'react-router-dom';

// import { getProductById } from '../../model/product';
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { UploadServices } from "../../services/UploadServices";
import { TagServices } from "../../services/TagServices";
import { TemplateServices } from "../../services/TemplateServices";
import { MaterialGroupServices } from "../../services/MaterialGroupServices";
import { myConfig } from "../../settings/config";
import moment from "moment";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";

import PerfectScrollbar from "react-perfect-scrollbar";

import Header from "../../components/Header";
import _ from "lodash";

function MaterialGroupPage(props) {
  const materialsGroupId = props.match.params.materialsGroupId;
  const [materials, setMaterials] = useState([]);

  const [materialGroupDetail, setMaterialGroupDetail] = useState(null);
  const [materialGroupNameEN, setMaterialGroupNameEN] = useState("");
  const [materialGroupNameZH, setMaterialGroupNameZH] = useState("");
  const [materialGroupNameSC, setMaterialGroupNameSC] = useState("");
  const [tags, setTags] = useState([]);
  const [isLive, setIsLive] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [selectedMaterial, setselectedMaterial] = useState([]);
  const [selectedAllMaterial, setSelectedAllMaterial] = useState(false);

  useEffect(() => {
    MaterialGroupServices.getMaterialGroupDetail(
      {
        materialGroupId: materialsGroupId,
      },
      loadMaterialGroupDetail
    );
  }, []);

  const loadMaterialGroupDetail = (respData) => {
    if (respData.ok) {
      const data = respData.data;
      setMaterialGroupNameEN(data.language_eng);
      setMaterialGroupNameZH(data.language_cht);
      setMaterialGroupNameSC(data.language_chs);
      setTags(data.tags);
      setIsLive(data.is_live);
      setIsActive(data.is_active);
      setMaterialGroupDetail(data);
    } else {
      console.log("respData", respData);
    }
  };

  const getMaterials = async () => {
    await TagServices.getMaterialsByMaterialGroupId(
      {
        group_id: materialsGroupId,
      },
      loadMaterials
    );
  };

  useEffect(() => {
    getMaterials();
  }, []);

  useEffect(() => {
    if (
      selectedMaterial.length === materials.length &&
      materials.length !== 0
    ) {
      setSelectedAllMaterial(true);
    } else {
      setSelectedAllMaterial(false);
    }
  }, [selectedMaterial.length, materials.length]);

  const loadMaterials = (respData) => {
    // error handling
    setMaterials(respData.data.result);
  };

  const handleUploadFiles = (e) => {
    if (e.target.files.length) {
      const requestData = {};
      UploadServices.uploadFiles(requestData, e.target.files, loadBucketUrl);
    }
  };

  const loadBucketUrl = (respData) => {
    if (respData.ok) {
      respData.data.uploaded.forEach(async (url) => {
        let requestData = {
          payload: {
            file_path: url,
            is_live: true,
            tags: [`materialgroups-${materialsGroupId}`],
          },
        };
        await TemplateServices.postMaterials(requestData, loadPostResult);
      });
    } else {
      if (respData.error && respData.error.message) {
        this.props.showPromptMessage(respData.error.message);
      }
    }
  };

  const loadPostResult = (respData) => {
    getMaterials();
  };

  const formatDate = (date) => {
    return moment(date).local().format("YYYY-MM-DD HH:mm:ss") || "";
  };

  const handleInputChange = (e, lang) => {
    if (lang === "EN") {
      setMaterialGroupNameEN(e.target.value);
    } else if (lang === "ZH") {
      setMaterialGroupNameZH(e.target.value);
    } else if (lang === "SC") {
      setMaterialGroupNameSC(e.target.value);
    } else {
      setTags(e.target.value.split(","));
    }
  };

  const updateMaterialGroupDetailHandler = () => {
    const apiData = {
      materialGroupId: materialGroupDetail._id,
      payload: {
        language_eng: materialGroupNameEN,
        language_cht: materialGroupNameZH,
        language_chs: materialGroupNameSC,
        tags: tags,
        is_live: isLive,
        is_active: isActive,
      },
    };
    MaterialGroupServices.updateMaterialGroupDetail(apiData, (apiRes) => {
      if (!apiRes.ok) {
        alert(apiRes?.error?.message || "unknown error");
      }

      alert("Update success");
    });
  };
  const handleMaterialGroupCheckboxChange = (m) => {
    setselectedMaterial(_.xorBy(selectedMaterial, [m], "_id"));
  };

  const toggleSelectAllMaterial = () => {
    if (selectedMaterial.length === materials.length) {
      setSelectedAllMaterial(false);
      setselectedMaterial([]);
    } else {
      setselectedMaterial(materials);
      setSelectedAllMaterial(true);
    }
  };

  const handleChangeSelectedLiveToUser = (newBool) => {
    Promise.all(
      selectedMaterial.map(async (m) => {
        await updateMaterial(m, newBool);
      })
    );
  };

  const updateMaterial = async (m, newBool) => {
    const newMaterial = m;
    newMaterial.is_live = newBool;
    newMaterial.file_path = newMaterial.bucket_path;
    const requestData = {
      materialId: newMaterial._id,
      payload: newMaterial,
    };
    await MaterialGroupServices.updateMaterialDetail(requestData, getMaterials);
  };
  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        style={{ background: "#f4f6f8", minHeight: "100vh" }}
      >
        <Container maxWidth="md">
          {/* <Breadcrumbs
            aria-label="breadcrumb"
            style={{ margin: "12px 0 24px" }}
          >
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">{materialsGroupId}</Typography>
          </Breadcrumbs> */}
          <Card>
            <CardHeader title="Material Group Info" />
            <CardContent>
              <form noValidate autoComplete="off">
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <TextField
                      label="English:"
                      style={{ margin: 5 }}
                      placeholder=""
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={materialGroupNameEN}
                      onChange={(e) => handleInputChange(e, "EN")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="繁體中文:"
                      style={{ margin: 5 }}
                      placeholder=""
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={materialGroupNameZH}
                      onChange={(e) => handleInputChange(e, "ZH")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="简体中文:"
                      style={{ margin: 5 }}
                      placeholder=""
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={materialGroupNameSC}
                      onChange={(e) => handleInputChange(e, "SC")}
                    />
                  </Grid>

                  {/* <Grid item xs={4}>
                    <Typography color="textPrimary">
                      Tags: {tags ? tags.join(",") : ""}
                    </Typography>
                  </Grid> */}

                  {/* <Grid item xs={4}>
                    <Typography color="textPrimary">
                      is Live: {isLive ? "true" : "false"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography color="textPrimary">
                      is Actibe: {isActive ? "true" : "false"}
                    </Typography>
                  </Grid> */}
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // xs={12}
                  >
                    <div style={{ display: "flex", marginTop: "30px" }}>
                      <div style={{ margin: "auto" }}>
                        <Button
                          color="primary"
                          startIcon={<ArrowLeftIcon />}
                          size="medium"
                          variant="outlined"
                          style={{ margin: "20px" }}
                          onClick={() => props.history.goBack()}
                        >
                          Back
                        </Button>
                        <Button
                          style={{ margin: "5px" }}
                          variant="contained"
                          color="primary"
                          onClick={updateMaterialGroupDetailHandler}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
          <Divider style={{ margin: "30px 0" }}></Divider>
          <Card>
            <CardHeader title="Material Group" />
            <CardContent>
              <form noValidate autoComplete="off">
                <Grid container spacing={4}>
                  <Grid
                    // item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // xs={12}
                  >
                    <div>
                      <Button
                        color="primary"
                        startIcon={<ArrowLeftIcon />}
                        size="medium"
                        variant="outlined"
                        style={{ margin: "20px" }}
                        onClick={() => props.history.goBack()}
                      >
                        Back
                      </Button>
                      <Button
                        color="primary"
                        component="label"
                        size="medium"
                        variant="contained"
                        style={{ margin: "20px" }}
                      >
                        Upload
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleUploadFiles}
                          multiple
                          hidden
                        />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
          <Divider style={{ margin: "30px 0" }}></Divider>
          <Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "15px 0" }}
                onClick={() => {
                  handleChangeSelectedLiveToUser(true);
                }}
              >
                Open selected to User
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "15px" }}
                onClick={() => {
                  handleChangeSelectedLiveToUser(false);
                }}
              >
                Close selected to User
              </Button>
            </Grid>
          </Grid>
          <Card>
            <CardHeader title="Materials" />
            <Divider />
            <PerfectScrollbar style={{ overflow: "auto" }}>
              <Box sx={{ minWidth: 400 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Template Name</TableCell> */}
                      <TableCell>
                        <Checkbox
                          checked={selectedAllMaterial}
                          onChange={toggleSelectAllMaterial}
                        />
                      </TableCell>
                      <TableCell>Thumbnail</TableCell>
                      {/* <TableCell>Tag(s)</TableCell> */}
                      <TableCell>Created at</TableCell>
                      {/* <TableCell>Updated at</TableCell> */}
                      <TableCell>Open to user</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materials
                      ? materials.map((material) => {
                          return (
                            <TableRow hover key={material._id}>
                              <TableCell>
                                <Checkbox
                                  checked={
                                    !_.isEmpty(
                                      selectedMaterial.filter(
                                        (m) => m._id === material._id
                                      )
                                    )
                                  }
                                  onChange={() => {
                                    handleMaterialGroupCheckboxChange(material);
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <img
                                  src={
                                    myConfig.bucketUrl + material.bucket_path
                                  }
                                  style={{
                                    maxWidth: "200px",
                                    maxHeight: "100px",
                                  }}
                                  alt="material-image"
                                />
                              </TableCell>
                              {/* <TableCell>
                                {material.tags ? material.tags.join(",") : ""}
                              </TableCell> */}
                              <TableCell>
                                {formatDate(material.created_at)}
                              </TableCell>
                              {/* <TableCell>
                                {formatDate(material.updated_at)}
                              </TableCell> */}
                              <TableCell>
                                {material.is_live ? "Yes" : "No"}
                                {/* <Switch checked={material.is_live} onChange={() => handleMaterialOnLiveChange(material)} /> */}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : ""}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
          <div style={{ display: "flex" }}>
            <Button
              color="primary"
              startIcon={<ArrowLeftIcon />}
              size="medium"
              variant="outlined"
              style={{ margin: "20px auto 0" }}
              onClick={() => props.history.goBack()}
            >
              Back
            </Button>
          </div>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default MaterialGroupPage;
