import React from "react";
import { Route, Redirect } from "react-router-dom";
import { myConfig } from "../settings/config";

export default ({ component: C, props: cProps, ...rest }) => {
  if (!cProps.isLoggedIn) {
    localStorage.removeItem("USER_DATA");
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        cProps.isLoggedIn ? (
          <C {...props} {...cProps} />
        ) : (
          <Redirect
            to={{
              pathname: myConfig.contextRoot + "login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
