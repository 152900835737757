import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";

import { TemplateServices } from "../../services/TemplateServices";
import { TagServices } from "../../services/TagServices";
import { updateTemplateGroupDetail } from "../../services/TemplateGroupServices";

import Header from "../../components/Header";
import _ from "lodash";

const AdsDetails = (props) => {
  const history = useHistory();

  const { adsType } = props.match.params;

  let ad_size = "";
  switch (adsType) {
    case "lrec":
      ad_size = "300x250";
      break;
    case "double-banner":
      ad_size = "640x200";
      break;
    case "triple-banner":
      ad_size = "640x300";
      break;
    case "pop-up":
      ad_size = "640x960";
      break;
    case "inverted-l-shape":
      ad_size = "1920x1080";
      break;
    default:
      ad_size = "300x250";
      break;
  }

  const [templateGroup, setTemplateGroup] = useState([]);
  const [newTemplateGroupNameEN, setNewTemplateGroupNameEN] = useState("");
  const [newTemplateGroupNameZH, setNewTemplateGroupNameZH] = useState("");
  const [newTemplateGroupNameSC, setNewTemplateGroupNameSC] = useState("");
  const [tags, setTags] = useState([ad_size]);
  const [selectedTemplateGroup, setselectedTemplateGroup] = useState([]);
  const [selectedAllTemplateGroup, setSelectedAllTemplateGroup] =
    useState(false);

  const getTemplateGroup = async () => {
    await TagServices.getTemplateGroup(
      {
        page: 1,
        size: 100,
        ad_size,
      },
      loadTemplateGroup
    );
  };

  useEffect(() => {
    getTemplateGroup();
  }, []);

  const loadTemplateGroup = (respData) => {
    if (respData && respData.data && respData.data.result) {
      setTemplateGroup(respData.data.result);
    }
  };

  useEffect(() => {
    if (
      selectedTemplateGroup.length === templateGroup.length &&
      templateGroup.length !== 0
    ) {
      setSelectedAllTemplateGroup(true);
    } else {
      setSelectedAllTemplateGroup(false);
    }
  }, [selectedTemplateGroup.length, templateGroup.length]);

  const formatDate = (date) => {
    return moment(date).local().format("YYYY-MM-DD HH:mm:ss") || "";
  };

  const handleAddTemplateGroup = async () => {
    const payload = {
      language_eng: newTemplateGroupNameEN,
      language_cht: newTemplateGroupNameZH,
      language_chs: newTemplateGroupNameSC,
      tags,
      is_live: true,
    };
    await TemplateServices.createTemplateGroup(
      {
        payload,
      },
      getTemplateGroup
    );
  };

  const handleInputChange = (e, lang) => {
    setTimeout(() => {
      if (lang === "EN") {
        setNewTemplateGroupNameEN(e.target.value);
      } else if (lang === "ZH") {
        setNewTemplateGroupNameZH(e.target.value);
      } else if (lang === "SC") {
        setNewTemplateGroupNameSC(e.target.value);
      } else {
        setTags(e.target.value.split(","));
      }
    }, 200);
  };

  const toggleSelectAllTemplateGroup = () => {
    if (selectedTemplateGroup.length === templateGroup.length) {
      setSelectedAllTemplateGroup(false);
      setselectedTemplateGroup([]);
    } else {
      setSelectedAllTemplateGroup(true);
      setselectedTemplateGroup(templateGroup);
    }
  };

  const handleTemplateGroupCheckboxChange = (t) => {
    setselectedTemplateGroup(_.xorBy(selectedTemplateGroup, [t], "_id"));
  };

  const handleChangeSelectedLiveToUser = (newBool) => {
    // Promise.all(
    selectedTemplateGroup.map(async (t) => {
      await updateTemplateGroup(t, newBool);
    });
    // )
  };

  const updateTemplateGroup = async (t, newBool) => {
    const newTemplateGroup = t;
    newTemplateGroup.is_live = newBool;
    // newTemplate.file_path = newTemplate.bucket_path;
    const requestData = {
      templateGroupId: newTemplateGroup._id,
      payload: newTemplateGroup,
    };
    // await TemplateServices.putTemplate(
    //     requestData,
    //     getTemplates
    //   );
    updateTemplateGroupDetail(requestData, (apiRes) => {
      if (!apiRes.ok) {
        alert(apiRes?.error?.message || "unknown error");
      }
      // alert("Update success");
      getTemplateGroup();
    });
  };

  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
        style={{ background: "#f4f6f8", minHeight: "100vh" }}
      >
        <Container maxWidth="md">
          {/* <Breadcrumbs
            aria-label="breadcrumb"
            style={{ margin: "12px 0 24px" }}
          >
            <Link color="inherit" href="/">
              Dashboard
            </Link>
            <Typography color="textPrimary">
              {props.match.params.adsType}
            </Typography>
          </Breadcrumbs> */}

          <Card>
            <CardHeader title="New Template Group" />
            <CardContent>
              <form noValidate autoComplete="off">
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="name-in-english"
                      label="English"
                      // value={newTemplateGroupNameEN}
                      onChange={(e) => handleInputChange(e, "EN")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="name-in-english"
                      label="繁體中文"
                      // value={newTemplateGroupNameZH}
                      onChange={(e) => handleInputChange(e, "ZH")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="name-in-english"
                      label="简体中文"
                      // value={newTemplateGroupNameSC}
                      onChange={(e) => handleInputChange(e, "SC")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      id="tags"
                      label="Tags"
                      defaultValue={tags}
                      onChange={(e) => handleInputChange(e, "tags")}
                    />
                  </Grid>
                </Grid>
              </form>

              <div style={{ display: "flex", marginTop: "30px" }}>
                <div style={{ margin: "auto" }}>
                  <Button
                    color="primary"
                    startIcon={<ArrowLeftIcon />}
                    size="medium"
                    variant="outlined"
                    style={{ margin: "20px" }}
                    onClick={() => props.history.goBack()}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none", margin: "20px" }}
                    onClick={handleAddTemplateGroup}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
          <Divider style={{ margin: "30px 0" }}></Divider>
          <Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "15px 0" }}
                onClick={() => {
                  handleChangeSelectedLiveToUser(true);
                }}
              >
                Open selected to User
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "15px" }}
                onClick={() => {
                  handleChangeSelectedLiveToUser(false);
                }}
              >
                Close selected to User
              </Button>
            </Grid>
          </Grid>
          <Card>
            <CardHeader title="Template Group" />
            <Divider />
            <PerfectScrollbar style={{ overflow: "auto" }}>
              <Box sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selectedAllTemplateGroup}
                          onChange={toggleSelectAllTemplateGroup}
                        />
                      </TableCell>
                      <TableCell>Group Name</TableCell>
                      <TableCell>Tag(s)</TableCell>
                      <TableCell>Created at</TableCell>
                      <TableCell>Updated at</TableCell>
                      <TableCell>Open to User</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {templateGroup.map((group) => (
                      <TableRow hover key={group._id}>
                        <TableCell>
                          <Checkbox
                            checked={
                              !_.isEmpty(
                                selectedTemplateGroup.filter(
                                  (t) => t._id === group._id
                                )
                              )
                            }
                            onChange={() => {
                              handleTemplateGroupCheckboxChange(group);
                            }}
                          />
                        </TableCell>
                        <TableCell>{group.language_eng}</TableCell>
                        <TableCell>
                          {group.tags ? group.tags.join(",") : ""}
                        </TableCell>
                        <TableCell>{formatDate(group.created_at)}</TableCell>
                        <TableCell>{formatDate(group.updated_at)}</TableCell>
                        <TableCell>{group.is_live ? "Yes" : "No"}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            size="medium"
                            variant="contained"
                            onClick={() =>
                              history.push(
                                `${props.match.url}/${group._id}/new-template`
                              )
                            }
                          >
                            New Template
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            endIcon={<ArrowRightIcon />}
                            size="medium"
                            variant="contained"
                            onClick={() =>
                              history.push(`${props.match.url}/${group._id}`)
                            }
                          >
                            View all
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
          <div style={{ display: "flex" }}>
            <Button
              color="primary"
              startIcon={<ArrowLeftIcon />}
              size="medium"
              variant="outlined"
              style={{ margin: "20px auto 0" }}
              onClick={() => props.history.goBack()}
            >
              Back
            </Button>
          </div>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default AdsDetails;
