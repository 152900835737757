import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const DashboardCard = (props) => {
  const history = useHistory();
  return (
    <Grid item lg={3} sm={6} xl={3} xs={12}>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="h6">
                {props.subtitle}
              </Typography>
              <Typography color="textPrimary" variant="h4">
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            onClick={() => history.push(props.href)}
          >
            View all
          </Button>
        </Box>
      </Card>
    </Grid>
  );
};

export default DashboardCard;
